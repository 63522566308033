.portfolio {
    padding: auto 0;
    text-align: center;
    font-family: 'Helvetica';
    scroll-snap-type:  y mandatory;
    overflow-y: scroll;
    height: 100vh;
}

.asian-bow h1 {
    user-select: none;
    margin: 0;
    margin-top: 25px;
    font-size: 2vw;
}

section {
    height:  100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    scroll-snap-align: start;
    user-select: none;
}

.one {
    background-color: #2C3531;
    flex-direction: column;
    font-size: 8vw;
    position: relative;
}
.two {
    background-color: #116466;
    font-size: 2vw;
}
.three {
    background-color: #D9B08C;
    font-size: 2vw;
}
.four {
    background-color: #FFCB9A;
    font-size: 2vw;
}
.five {
    background-color: #D1E8E2;
    font-size: 2vw;
}

.box {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.box span {
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.box span:nth-child(2) {
    animation-delay: -0.2s;
}
.box span:nth-child(3) {
    animation-delay: -0.4s;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

@media (max-width: 420px) {
    .asian-bow h1 {
        font-size: 8vw;
    }
    .one {
        font-size: 15vw;
    }
    .two {
        font-size: 6vw;
    }
    .three {
        font-size: 6vw;
    }
    .four {
        font-size: 6vw;
    }
    .five {
        font-size: 6vw;
    }
    .box {
        bottom: 8%;
    }
    .box span {
        width: 20px;
        height: 20px;
    }
}

/*.myName:hover {
    animation:  rubberBand 1s;
}*/

/*@keyframes rubberBand {
    0% {
        transform: scale3d(1, 1, 1);
    }
    35% {
        transform: scale3d(1.25, 0.75, 1);
    }
    45% {
        transform: scale3d(0.75, 1, 1);
    }
    60% {
        transform: scale3d(1.2, .8, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}*/
